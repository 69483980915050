const routes = [
  { 
    path: '/library/courses', 
    name: 'Courses',
    component: () => import('./courses/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_courses']
          }  
  },
  { 
    path: '/library/courses/new', 
    name: 'NewCourse',
    component: () => import('./courses/CreateCourse.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['create_courses']
          } 
  },
  { 
    path: '/library/courses/:uuid', 
    name: 'ViewCourse',
    component: () => import('./courses/Course.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['view_courses']
          }
  },
  { 
    path: '/library/courses/:uuid/edit', 
    name: 'EditCourse',
    component: () => import('./courses/EditCourse.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['update_courses']
          }
  },
  { 
    path: '/library/lessons', 
    name: 'Lessons',
    component: () => import('./lessons/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_lessons']
          } 
  },
  { 
    path: '/library/lessons/:uuid', 
    name: 'ViewLesson',
    component: () => import('./lessons/Lesson.vue'), 
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['view_lessons']
          } 
  },
  { 
    path: '/library/categories', 
    name: 'Categories',
    component: () => import('./categories/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_categories']
          } 
  },
  { 
    path: '/library/tags',
    name: 'tags', 
    component: () => import('./tags/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_tags']
          }
  },
];

export default routes;