const routes = [
  { 
    path: '/statistics-reports/course-man-days', 
    name: 'CourseManDays',
    component: () => import('./course-man-days/index.vue'),
    // meta: { 
    //         roles: ['Reports'], 
    //         permissions: ['list_roles']
    //       } 
  },
  { 
    path: '/statistics-reports/training-services', 
    name: 'TrainingServices',
    component: () => import('./training-services/index.vue'),
    // meta: { 
    //         roles: ['Reports'], 
    //         permissions: ['list_roles']
    //       } 
  },
];

export default routes;