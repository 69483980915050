const routes = [
  { 
    path: '/schedules/course-schedule', 
    name: 'Course Schedule',
    component: () => import('./CourseSchedule.vue'),
  },
  { 
    path: '/schedules/instructor-schedule', 
    name: 'Instructor Schedule',
    component: () => import('./InstructorSchedule.vue'),
  },
];

export default routes;