// Utilities
import { env } from '@/env'
import router from '@/router'
import axios from '@/api/axios'
import { defineStore } from 'pinia'
import * as CryptoJS from 'crypto-js'
import Me from '@/plugins/interfaces/Me'

export default defineStore('auth', 
{
    state: () => ({ 
        me: {} as Me,
        loggedIn: (localStorage.getItem(env.tokenName) !== null),
        credentials: 
        {
            email: '',
            password: ''
        },
        loading: false,
        processing: false,
        passwordVisible: false,
    }),

    getters:
    {
        getMe: (state) => state.me,
        isLoading: (state) => state.loading,
        isProcessing: (state) => state.processing,
        isUserLoggedIn: (state) => state.loggedIn,
        isPasswordVisible: (state) => state.passwordVisible,
    },

    actions: 
    {
        async login()
        {
            this.processing = true

            try
            {    
                const response = await axios.post('/auth/login', this.credentials)

                if(response.status === 200)
                {
                    localStorage.setItem(env.tokenName, response.data.token)

                    const userPermissions = {
                        roles: response.data.roles,
                        permissions: response.data.permissions
                    }

                    this.me.roles = response.data.roles
                    this.me.permissions = response.data.permissions

                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userPermissions), env.cryptoKey).toString()

                    localStorage.setItem(env.encryptedDataName, encryptedData)

                    router.push({ path: '/' })

                    this.processing = false

                    this.loggedIn = true
                }

                return response
            }
            catch(error) 
            {
                this.processing = false

                throw error
            }
        },

        async logout()
        {
            this.processing = true

            try
            {
                const response = await axios.post('/auth/logout')

                if(response.status === 200)
                {
                    this.loggedIn = false

                    localStorage.removeItem(env.tokenName)

                    localStorage.removeItem(env.encryptedDataName)

                    router.push({ path: '/login' })

                    this.processing = false
                }

                return response
            }                  
            catch(error)
            {
                this.processing = false

                throw error
            }
        },

        async authMe()
        {
            this.processing = true

            try
            {
                const response = await axios.get('/auth/me')

                if(response.status === 200)
                {
                    this.me = response.data

                    const userPermissions = {
                        roles: response.data.roles,
                        permissions: response.data.permissions
                    }

                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userPermissions), env.cryptoKey).toString()

                    if(localStorage.getItem(env.encryptedDataName) !== encryptedData)
                    {
                        localStorage.setItem(env.encryptedDataName, encryptedData)
                    }
                }

                return response
            }
            catch(error)
            {
                this.processing = false

                throw error
            }
        }
    }
})