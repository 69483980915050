import { env } from '@/env'
import * as CryptoJS from 'crypto-js'
import _, { isEmpty, cloneDeep } from 'lodash'
import useAuthStore from '@/stores/authentication/auth'
import { RouteLocationNormalizedLoaded } from 'vue-router'

export type RequiredPermission = 
{
    roles: string[],
    permissions: string[],
}

const hasRequiredAccessRights = (required:RequiredPermission) =>
{
    const userAuth = useAuthStore()

    let user = cloneDeep(userAuth.getMe)

    if(user.me === undefined)
    {
        const encryptedData = localStorage.getItem(env.encryptedDataName)

        if (encryptedData) 
        {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, env.cryptoKey)
            const userData = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8))

            user.roles = userData.roles
            user.permissions = userData.permissions
        }
    }

    if(userAuth.isUserLoggedIn && !user.roles.includes('Administrator'))
    {   
        const hasRequiredRoles = required.roles !== undefined && required.roles.length ? user.roles.some(role => required.roles.includes(role as never)) : true

        const hasRequiredPermissions = user.permissions.some(permission => required.permissions.includes(permission as never))

        if (!hasRequiredRoles || !hasRequiredPermissions) 
        {
            return false
        }
    }

    return true
}

export const queryString = (route:RouteLocationNormalizedLoaded) =>
{
    const params = {}
    
    _.each(route.query, ( val, key ) => 
    { 
        if(!isEmpty(val)) _.assign(params, {[key]:val})
    })

    return params
} 

export const sortIcon = (route:RouteLocationNormalizedLoaded, fieldName:string) =>
{
    if(route.query.sortBy == fieldName)
    {
        return (route.query.dir == 'desc' ? 'mdi-sort-descending' : 'mdi-sort-ascending')
    }

    return undefined
}

export default hasRequiredAccessRights