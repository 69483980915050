export default interface Me 
{
    me: {
        id?: number,
        uuid?: string,
        email: string,
        is_active: number,
        roles: { [key: number]: string },
        created_at: string,
        updated_at: string,
        profile: 
        {
            avatar_path: string | null,
            complete_name: string,
            first_name: string,
            middle_name: string,
            last_name: string,
            date_of_birth: string,
            address: string,
            email: string,
            mobile_number: string,
            telephone_number: string,
            company: 
            {
                name: string,
            }
        }
    },
    roles: string[],
    permissions: string[]
}

export const MeRef = <Me> {
    me: {
            email: '',
            is_active: 0,
            roles: {},
            created_at: '',
            updated_at: '',
            profile: 
            {
                avatar_path: null,
                complete_name: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                date_of_birth: '',
                address: '',
                email: '',
                mobile_number: '',
                telephone_number: '',
                company: 
                {
                    name: '',
                }
            }
        },
        roles: [],
        permissions: []
}
