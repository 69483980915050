// export const env =
// {
//     tokenName: 'VGDngJ6iPER5jVyyYrSbe5iWu8eCxFKn2zNi0gdfDCfVshex',
//     baseURL: 'http://tasv2-api.gigamare.loc/api/v1',
//     cryptoKey: 'UJ79gIQAtVq0Zjm7aRhOzIiIUYL446IQGmcZ2wkAW5wl1ajR',
//     encryptedDataName: 'pex5CThPGfFUf1T1Q2JLftIRI1jKWU00LZrZKE4ageMOH142'
// }
export const env =
{
    tokenName: 'VGDngJ6iPER5jVyyYrSbe5iWu8eCxFKn2zNi0gdfDCfVshex',
    baseURL: 'https://teal-api.gigamare.com/api/v1',
    cryptoKey: 'UJ79gIQAtVq0Zjm7aRhOzIiIUYL446IQGmcZ2wkAW5wl1ajR',
    encryptedDataName: 'pex5CThPGfFUf1T1Q2JLftIRI1jKWU00LZrZKE4ageMOH142'
}