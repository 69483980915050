const routes = [
  { 
    path: '/', 
    name: 'Dashboard', 
    component: () => import('@/layouts/default/dashboard.vue'),
    meta: { 
            roles: ['Settings', 'Coordinator', 'Instructor', 'Trainee'], 
            permissions: []
          } 
  },
];

export default routes;