/**
 * directives/can/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Dependencies
import { env } from '@/env'
import { cloneDeep } from 'lodash'
import * as CryptoJS from 'crypto-js'
import { DirectiveBinding } from 'vue'
import useAuthStore from '@/stores/authentication/auth'


const canDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding<any>) 
  {

    const userAuth = useAuthStore()

    let user = cloneDeep(userAuth.getMe)

    if(user.me === undefined)
    {
        const encryptedData = localStorage.getItem(env.encryptedDataName)

        if (encryptedData) 
        {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, env.cryptoKey)
            const userData = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8))

            user.roles = userData.roles
            user.permissions = userData.permissions
        }
    }

    // const required = binding.value

    if(userAuth.isUserLoggedIn && !user.roles.includes('Administrator'))
    {   
        // const hasRequiredRoles = required.roles !== undefined && required.roles.length ? user.roles.some(role => required.roles.includes(role as never)) : true

        // const hasRequiredPermissions = user.permissions.some(permission => required.permissions.includes(permission as never))
        const hasRequiredPermissions = user.permissions.some(permission => (permission === binding.value))

        // if (!hasRequiredRoles || !hasRequiredPermissions) 
        if (!hasRequiredPermissions) 
        {
            el.remove()
        }
    }
  },
}

export default canDirective

