<script setup lang="ts">
    import { cloneDeep } from 'lodash'
    import { ref, onMounted } from 'vue'
    import { MeRef } from '@/plugins/interfaces/Me'
    import useAuthStore from '@/stores/authentication/auth'

    const userAuth = useAuthStore()

    const me = ref(cloneDeep(MeRef))

    onMounted(() =>
    {
        userAuth.authMe()
            .then((response) =>
            {
                if(response.status === 200)
                    me.value = userAuth.getMe
            })
            .catch((error) => 
            {
                console.log(error)
            })
    })
</script>

<template>
    <v-app-bar elevation="12" color="teal-lighten-5">
        <v-sheet class="bg-transparent text-teal ml-5">
            <v-img :width="90" src="@/assets/img/teal-logo-small.png"  />
        </v-sheet>
        <v-sheet class="bg-transparent subtitle-3 text-teal ml-4 mt-2">- Efficient Training Management System</v-sheet>

        <v-list-item
          :title="`${me.me.profile.complete_name}`"
          :subtitle="me.me.email"
          class="ml-auto mr-5" 
          style="cursor:pointer;"
          nav>
            <template v-slot:prepend>
                <v-avatar v-if="(me.me.id !== 90)" image="@/assets/login/default-profile.jpg"/>
                <v-avatar v-else image="@/assets/login/default-avatar.png"/>
            </template>
            <v-menu activator="parent">
                <v-list nav>
                    <v-list-item prepend-icon="mdi-cog" value="account">Account Settings</v-list-item>
                    <v-divider></v-divider>
                    <v-list-item prepend-icon="mdi-logout" @click="userAuth.logout">Logout</v-list-item>
                </v-list>
            </v-menu>
        </v-list-item>
    </v-app-bar>
</template>