import { env } from '@/env'
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

// Create an Axios instance with custom configuration
const instance = axios.create({
  baseURL: env.baseURL,
  // timeout: 5000,
  headers: {
    'Authorization': `Bearer ${window.localStorage.getItem(env.tokenName)}`,
    'Content-Type': 'application/json'
  },
});

// Request interceptor for setting common headers or modifying requests
instance.interceptors.request.use((config: InternalAxiosRequestConfig) => 
{
  config.headers['Authorization'] = `Bearer ${window.localStorage.getItem(env.tokenName)}`
  
  return config
},
(error) => 
{
    return Promise.reject(error);
})

// Response interceptor for handling common response logic
instance.interceptors.response.use((response: AxiosResponse) => 
{
  // You can handle common response logic here, e.g., logging, authentication checks, etc.
  return response;
},
(error) => 
{
  // Handle errors here, e.g., logging or showing error messages
  return Promise.reject(error)
})

export default instance;