<script setup lang="ts">
    import { ref, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import hasRequiredAccessRights from '@/utils/globals'
    
    const route = useRoute()

    const rail = ref(false)
    const activeSubMenu = ref()
    const activeMenu = ref([] as string[])

    watch(() => route.fullPath, async newPath => 
    {
        const paths = newPath.split('/')

        activeMenu.value = paths[1] ? [`${paths[1].charAt(0).toUpperCase()}${paths[1].slice(1)}`] : [] as string[]

        activeSubMenu.value = paths[2] ? paths[2].toString() : ''
    })
</script>

<style scoped>
  .no-underline-link 
  {
      text-decoration: none;
      /* Remove the underline */
      color: inherit;
      /* Inherit the text color */
  }
</style>

<template>
    <v-navigation-drawer permanent :rail="rail" color="teal-lighten-4">
        <v-list-item nav>
            <v-img :width="150" class="ma-2" src="@/assets/img/teal-logo.png" :hidden="rail" />
            <template v-slot:append>
                <v-btn
                  variant="text"
                  icon="mdi-chevron-left"
                  @click.stop="rail = !rail"
                  color="default"
                  :hidden="rail"></v-btn>
                <v-btn
                  variant="text"
                  icon="mdi-format-align-justify"
                  @click.stop="rail = !rail"
                  color="default"
                  :hidden="!rail"></v-btn>
            </template>
        </v-list-item>
        <v-divider></v-divider>

        <v-list density="compact" nav @click.stop="rail = false" v-model:opened="activeMenu">
            <router-link to="/" class="no-underline-link" tag="v-list-item">
                <v-list-item
                  :active="activeMenu.length === 0"
                  prepend-icon="mdi-monitor-dashboard"
                  title="Dashboard"
                  value="dashboard" />
            </router-link>

            <v-list-item prepend-icon="mdi-email-fast-outline" title="Inbox" value="inbox" />

            <v-divider v-if="hasRequiredAccessRights({ roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], permissions: ['list_trainings', 'list_instructors', 'list_learners', 'list_classrooms', 'list_locations'], })"></v-divider>

            <v-list-group value="Classes" v-if="hasRequiredAccessRights({ roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], permissions: ['list_trainings', 'list_instructors', 'list_learners', 'list_classrooms', 'list_locations'], })">
                <template v-slot:activator="{ props }">
                    <v-list-item prepend-icon="mdi-school-outline" title="Classes" v-bind="props" />
                </template>

                <router-link
                  to="/classes/trainings"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_trainings'">
                    <v-list-item
                      :active="activeSubMenu === 'trainings'"
                      prepend-icon="mdi-account-school-outline"
                      title="Trainings"
                      value="trainings" />
                </router-link>

                <router-link
                  to="/classes/instructors"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_instructors'">
                    <v-list-item
                      :active="activeSubMenu === 'instructors'"
                      prepend-icon="mdi-human-male-board-poll"
                      title="Instructors"
                      value="instructors" />
                </router-link>

                <router-link
                  to="/classes/learners"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_learners'">
                    <v-list-item
                      :active="activeSubMenu === 'learners'"
                      prepend-icon="mdi-library-outline"
                      title="Learners"
                      value="learners" />
                </router-link>

                <router-link
                  to="/classes/classrooms"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_classrooms'">
                    <v-list-item
                      :active="activeSubMenu === 'classrooms'"
                      prepend-icon="mdi-home-switch-outline"
                      title="Classrooms"
                      value="classrooms" />
                </router-link>

                <router-link
                  to="/classes/locations"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_locations'">
                    <v-list-item
                      :active="activeSubMenu === 'locations'"
                      prepend-icon="mdi-map-marker-outline"
                      title="Locations"
                      value="locations" />
                </router-link>
            </v-list-group>

            <v-divider v-if="hasRequiredAccessRights({ roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], permissions: ['list_courses', 'list_lessons', 'list_categories'], })"></v-divider>

            <v-list-group value="Library" v-if="hasRequiredAccessRights({ roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], permissions: ['list_courses', 'list_lessons', 'list_categories'], })">
                <template v-slot:activator="{ props }">
                    <v-list-item prepend-icon="mdi-bookshelf" title="Library" v-bind="props" />
                </template>

                <router-link
                  to="/library/courses"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_courses'">
                    <v-list-item
                      :active="activeSubMenu === 'courses'"
                      prepend-icon="mdi-book-open-variant"
                      title="Courses"
                      value="courses"
                      class="ma-0" />
                </router-link>

                <router-link
                  to="/library/lessons"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_lessons'">
                    <v-list-item
                      :active="activeSubMenu === 'lessons'"
                      prepend-icon="mdi-book-open-page-variant-outline"
                      title="Lessons"
                      value="lessons"
                      class="ma-0" />
                </router-link>

                <router-link
                  to="/library/categories"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_categories'">
                    <v-list-item
                      :active="activeSubMenu === 'categories'"
                      prepend-icon="mdi-format-list-text"
                      title="Categories"
                      value="categories"
                      class="ma-0" />
                </router-link>

                <router-link
                  to="/library/tags"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_tags'">
                    <v-list-item
                      :active="activeSubMenu === 'tags'"
                      prepend-icon="mdi-bookmark-check-outline"
                      title="Tags"
                      value="tags"
                      class="ma-0" />
                </router-link>
            </v-list-group>

            <v-divider></v-divider>

            <v-list-group value="Schedules">
                <template v-slot:activator="{ props }">
                    <v-list-item prepend-icon="mdi-clipboard-text-clock-outline" title="Schedules" v-bind="props" />
                </template>

                <router-link
                  to="/schedules/course-schedule"
                  class="no-underline-link"
                  tag="v-list-item">
                    <v-list-item
                      :active="activeSubMenu === 'course-schedule'"
                      prepend-icon="mdi-book-open-outline"
                      title="Courses"
                      value="courses"
                      class="ma-0" />
                </router-link>

                <router-link
                  to="/schedules/instructor-schedule"
                  class="no-underline-link"
                  tag="v-list-item">
                  <v-list-item
                    :active="activeSubMenu === 'instructor-schedule'"
                    prepend-icon="mdi-human-male-board"
                    title="Instructors"
                    value="instructors"
                    class="ma-0" />
                </router-link>

                <v-list-item
                  :active="activeSubMenu === 'classrooms'"
                  prepend-icon="mdi-home-clock-outline"
                  title="Classrooms"
                  value="classrooms"
                  class="ma-0" />
            </v-list-group>

            <v-divider></v-divider>

            <v-list-group value="Statistics-reports">
                <template v-slot:activator="{ props }">
                    <v-list-item prepend-icon="mdi-chart-scatter-plot-hexbin" title="Statistics Report" v-bind="props" />
                </template>

                <router-link
                  to="/statistics-reports/course-man-days"
                  class="no-underline-link"
                  tag="v-list-item">
                  <v-list-item
                    :active="activeSubMenu === 'course-man-days'"
                    prepend-icon="mdi-chart-timeline-variant-shimmer"
                    title="Course Man Days"
                    value="course-man-days"
                    class="ma-0" />
                </router-link>
                
                <router-link
                  to="/statistics-reports/training-services"
                  class="no-underline-link"
                  tag="v-list-item">
                  <v-list-item
                    :active="activeSubMenu === 'training-services'"
                    prepend-icon="mdi-chart-timeline"
                    title="Training Services"
                    value="training-services"
                    class="ma-0" />
                </router-link>
            </v-list-group>

            <v-divider></v-divider>


            <v-list-group value="Settings" v-if="hasRequiredAccessRights({ roles: ['Settings', 'Coordinator'], permissions: ['list_users', 'list_positions', 'list_companies', 'list_roles', 'list_permissions'],})">
                <template v-slot:activator="{ props }">
                    <v-list-item prepend-icon="mdi-wrench-cog-outline" title="Settings" v-bind="props" />
                </template>

                <router-link
                  to="/settings/users"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_users'">
                    <v-list-item
                    :active="activeSubMenu === 'users'"
                    prepend-icon="mdi-account-group-outline"
                    title="Users"
                    value="users"
                    class="ma-0" />
                </router-link>

                <router-link
                  to="/settings/positions"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_positions'">
                    <v-list-item
                      :active="activeSubMenu === 'positions'"
                      prepend-icon="mdi-police-badge-outline"
                      title="Positions"
                      value="positions"
                      class="ma-0" />
                </router-link>

                <router-link
                  to="/settings/organizations"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-can="'list_companies'">
                    <v-list-item
                      :active="activeSubMenu === 'organizations'"
                      prepend-icon="mdi-file-tree-outline"
                      title="Organizations"
                      value="organizations"
                      class="ma-0" />
                </router-link>

                <router-link
                  to="/settings/roles"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-if="hasRequiredAccessRights({ roles: ['Settings'], permissions: ['list_roles'],})">
                    <v-list-item
                      :active="activeSubMenu === 'roles'"
                      prepend-icon="mdi-account-check-outline"
                      title="Roles"
                      value="roles"
                      class="ma-0" />
                </router-link>

                <router-link
                  to="/settings/permissions"
                  class="no-underline-link"
                  tag="v-list-item"
                  v-if="hasRequiredAccessRights({ roles: ['Settings'], permissions: ['list_permissions'],})">
                    <v-list-item
                      :active="activeSubMenu === 'permissions'"
                      prepend-icon="mdi-lock-open-check-outline"
                      title="Permissions"
                      value="permissions"
                      class="ma-0" />
                </router-link>
            </v-list-group>
        </v-list>

    </v-navigation-drawer>
</template>