const routes = [
  { 
    path: '/settings/roles', 
    name: 'Roles',
    component: () => import('./roles/list.vue'),
    meta: { 
            roles: ['Settings'], 
            permissions: ['list_roles']
          } 
  },
  { 
    path: '/settings/permissions', 
    name: 'Permissions',
    component: () => import('./permissions/list.vue'),
    meta: { 
            roles: ['Settings'], 
            permissions: ['list_permissions']
          } 
  },
  { 
    path: '/settings/positions', 
    name: 'Positions',
    component: () => import('./positions/list.vue'),
    meta: { 
            roles: ['Settings', 'Coordinator'],
            permissions: ['list_positions']
          }
  },
  { 
    path: '/settings/organizations', 
    name: 'Organizations',
    component: () => import('./organizations/list.vue'),
    meta: { 
            roles: ['Settings', 'Coordinator'],
            permissions: ['list_companies']
          }
  },
  { 
    path: '/settings/users', 
    name: 'Users',
    component: () => import('./users/list.vue'),
    meta: { 
            roles: ['Settings', 'Coordinator'],
            permissions: ['list_users']
          } 
  },
  { 
    path: '/settings/users/:uuid', 
    name: 'ViewUsers',
    component: () => import('./users/User.vue'),
    meta: { 
            roles: ['Settings', 'Coordinator'],
            permissions: ['view_users']
          } 
  },
];

export default routes;