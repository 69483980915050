const routes = [
  { 
    path: '/classes/trainings', 
    name: 'Trainings',
    component: () => import('./trainings/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_trainings']
          }  
  },
  { 
    path: '/classes/trainings/new', 
    name: 'NewTraining',
    component: () => import('./trainings/CreateTraining.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['create_trainings']
          } 
  },
  { 
    path: '/classes/trainings/:uuid', 
    name: 'ViewTraining',
    component: () => import('./trainings/Training.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['view_trainings']
          }
  },
  { 
    path: '/classes/trainings/:uuid/edit', 
    name: 'EditTraining',
    component: () => import('./trainings/EditTraining.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['update_trainings']
          }
  },
  { 
    path: '/classes/instructors', 
    name: 'Instructors',
    component: () => import('./instructors/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_instructors']
          } 
  },
  { 
    path: '/classes/instructors/:uuid', 
    name: 'ViewInstructor',
    component: () => import('./instructors/Instructor.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['view_instructors']
          } 
  },
  { 
    path: '/classes/learners', 
    name: 'Learners',
    component: () => import('./learners/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_learners']
          } 
  },
  { 
    path: '/classes/learners/:uuid', 
    name: 'ViewLearner',
    component: () => import('./learners/Learner.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['view_learners']
          } 
  },
  { 
    path: '/classes/classrooms', 
    name: 'Classrooms',
    component: () => import('./classrooms/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_classrooms']
          }
  },
  { 
    path: '/classes/locations',
    name: 'Locations', 
    component: () => import('./locations/list.vue'),
    meta: { 
            roles: ['Coordinator', 'Instructor', 'Trainee', 'Customer'], 
            permissions: ['list_locations']
          }
  },
];

export default routes;